import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/en/Layout"
import HomeSection from "../../components/en/HomeSection"
import PopularCard from "../../components/en/PopularCard"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import SEO from "../../components/en/SEO"

const Trending = ({ data }) => {
  const cardsTrendingMonth = data.allTrendingMonthEn.edges[0].node
  const cardsTrending = data.allTrendingEn.edges[0].node

  return (
    <Layout>
      <SEO
        title="Trending"
        canonical={`${process.env.GATSBY_HOST_URL}/en/trending`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/trending`}
      />

      <div className="main-content topics">
        <Breadcrumbs
          currentPage="Trending"
          style={{ marginTop: 117, marginBottom: 30 }}
        />

        <div className="topics-header">
          <h1>Top Picks</h1>
          <p style={{ maxWidth: 650 }}>
            Explore the most read histories and inspirational ideas
          </p>
        </div>

        <div
          className="home-section trending-home-section"
          style={{ marginTop: 50 }}
        >
          <div className="home-section-header">
            <span>TODAY</span>
          </div>

          {cardsTrending.cards.slice(0, 4).map(card => (
            <PopularCard card={card} customClass="popular-card-list" />
          ))}
        </div>

        <HomeSection
          name="Most popular last month"
          cards={cardsTrendingMonth.cards.slice(0, 4)}
          style={{ marginTop: 50, marginBottom: 50 }}
          isCollection={true}
          customClass="home-section-card-list"
        />
      </div>
    </Layout>
  )
}

export default Trending

export const query = graphql`
  query {
    allTrendingMonthEn {
      edges {
        node {
          cards {
            name
            slug
            imageUrl
            createdAt
            type
            topic {
              name
              slug
              color
            }
            tags {
              name
            }
            meta {
              author
            }
          }
        }
      }
    }

    allTrendingEn(limit: 5) {
      edges {
        node {
          cards {
            name
            slug
            imageUrl
            type
            topic {
              name
              slug
              color
            }
            tags {
              name
            }
            meta {
              content
              author
            }
          }
        }
      }
    }
  }
`
