import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import TrendIcon from "../../images/icons/Trend"

import "./PopularCard.css"

const PopularCard = ({ card, customClass }) => {
  const [cardContent, setCardContent] = useState("")

  useEffect(() => {
    if (card.meta.content) {
      let localContent = document.createElement("div")
      localContent.innerHTML = card.meta.content

      localContent =
        localContent.innerText
          .replace(/(<([^>]+)>)/gi, "")
          .trim()
          .substring(0, 200) + "..."

      setCardContent(localContent)
    } else {
      let localContent = document.createElement("div")
      localContent.innerHTML = card.meta.excerpt

      localContent =
        localContent.innerText
          .replace(/(<([^>]+)>)/gi, "")
          .trim()
          .substring(0, 200) + "..."

      setCardContent(localContent)
    }
  }, [])

  return (
    <Link
      to={`/en/cards/${card.slug}`}
      className={`popular-card ${customClass ? customClass : ""}`}
    >
      <TrendIcon />

      {card.type === "quote" ? (
        <div
          className="popular-card-img"
          style={{
            backgroundImage: `url(${card.imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#fff",
            padding: "30px 0",
          }}
        ></div>
      ) : (
        <div
          className="popular-card-img"
          style={{
            backgroundImage: `url(${card.imageUrl})`,
            backgroundSize: "cover",
          }}
        ></div>
      )}

      <div className="popular-card-info">
        <span
          style={{ color: card.topic.color }}
          className="popular-card-topic"
        >
          {card.topic.name}
        </span>

        <h3>{card.name}</h3>

        {card.type === "quote" ? (
          <small
            style={{
              textTransform: "none",
              color: card.topic.color,
              fontWeight: 500,
              fontSize: 16,
              marginTop: 10,
              marginBottom: 5,
            }}
          >
            - {card.meta.author}
          </small>
        ) : null}

        <div
          className="popular-card-info-excerpt"
          dangerouslySetInnerHTML={{
            __html: cardContent,
          }}
        ></div>

        <div className="popular-card-tags">
          {card.tags.slice(0, 2).map(tag => (
            <span>{tag.name}</span>
          ))}
        </div>
      </div>
    </Link>
  )
}

export default PopularCard
